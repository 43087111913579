import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {environment} from "../../environments/environment";
import {EquipmentService} from "../equipment.service";
export interface DialogData {
  number: string;
  id: string;
}

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.scss']
})
export class QrcodeDialogComponent implements OnInit {
  code: string;
  number: string;
  width = 150;
  numberToCheck: string;
  response: string;
  constructor(public dialogRef: MatDialogRef<QrcodeDialogComponent>, public equipmentService: EquipmentService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.number = data['number'];
    this.code = environment.clientUrl + this.number;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async checkNumber() {
    this.response = await this.equipmentService.checkNumber(this.numberToCheck);
  }
}
