import {Routes} from '@angular/router';

import {AdminLayoutComponent, AuthLayoutComponent} from './core';

export const AppRoutes: Routes = [{
  path: '',
  component: AdminLayoutComponent,
  children: [
    {
      path: 'user',
      loadChildren: () => import('./user-admin/user-admin.module').then(m => m.UserAdminModule)
    },
    {
      path: 'config',
      loadChildren: () => import('./config/config.module').then(m => m.ConfigModule)
    },
    {
      path: 'equipment',
      loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule)
    },
    {
      path: 'phone',
      loadChildren: () => import('./phone/phone.module').then(m => m.PhoneModule)
    },
    {
      path: '',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    }
  ]
},
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'session',
      loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
    }]
  }, {
    path: '**',
    redirectTo: 'session/404'
  }];
