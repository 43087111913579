import { Injectable } from '@angular/core';
import {EquipmentUpdateEvent} from "./equipment-update-event";
import {EquipmentDto} from "./equipment-dto";
import {environment} from "../environments/environment";
import {ConfigDto} from "./config-dto";
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  eventListener: Array<EquipmentUpdateEvent>
  equipment: EquipmentDto[];
  constructor(private auth: AuthService, private httpClient: HttpClient) {
    this.eventListener = new Array<EquipmentUpdateEvent>();
  }

  addListener(listener: EquipmentUpdateEvent) {
    this.eventListener.push(listener);
  }

  async init() {
    const url = environment.backendUrl + "/admin/equipment/list";
    this.equipment = await this.httpClient.get<EquipmentDto[]>(url, {headers: this.auth.headers}).toPromise();
    this.eventListener.forEach(x => x.equipmentUpdated());
  }

  async updateOrCreate(equipmentDto: EquipmentDto) {
    const url = environment.backendUrl + "/admin/equipment";
    await this.httpClient.post(url, equipmentDto, {headers: this.auth.headers}).toPromise();
    this.init();
  }

  async checkNumber(number: string) {
    const url = environment.backendUrl + "/admin/checkEquipment/" + number;
    return await this.httpClient.get<string>(url, {headers: this.auth.headers}).toPromise();
  }
}
