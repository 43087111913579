import screenfull from 'screenfull';

import { Component, EventEmitter, Output } from '@angular/core';
import {AuthService} from "../../auth.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ChangePasswordComponent} from "../../change-password/change-password.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Output()
  toggleSidenav = new EventEmitter<void>();

  constructor(public auth: AuthService, public dialog: MatDialog) {}

  fullScreenToggle(): void {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
