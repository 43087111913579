import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {UserDto} from "./user-dto";
import {UserUpdateEvent} from "./user-update-event";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  allUsers: UserDto[];
  eventListener: Array<UserUpdateEvent>
  constructor(private auth: AuthService, private httpClient: HttpClient) {
    this.eventListener = new Array<UserUpdateEvent>();
  }

  registerEventListener(listener: UserUpdateEvent) {
    this.eventListener.push(listener);
  }

  async loadUser() {
    console.log('load users');
    this.allUsers = await this.getAllUsers();
    this.eventListener.forEach(x => x.userUpdated());
  }

  getAllUsers(): Promise<UserDto[]> {
    const url = environment.backendUrl + "/admin/users/list";
    return this.httpClient.get<UserDto[]>(url, {headers: this.auth.headers}).toPromise();
  }

  async deleteUser(username: string) {
    const url = environment.backendUrl + "/admin/users/" + username;
    await this.httpClient.delete(url, {headers: this.auth.headers}).toPromise();
    this.loadUser();
  }

  resetPassword(username: string) {
    const url = environment.backendUrl + "/admin/users/" + username + "/reset";
    this.httpClient.post(url, null, {headers: this.auth.headers}).toPromise().catch(ex => {
      console.log(ex);
    });
  }

  changePasswordOfLocalUser(password: string) {
    const url = environment.backendUrl + "/changePassword";
    this.httpClient.post(url, password, {headers: this.auth.headers}).toPromise().catch(ex => {
      console.log(ex);
    });
  }

  async updateUser(user: UserDto) {
    const url = environment.backendUrl + "/admin/users";
    await this.httpClient.post(url, user, {headers: this.auth.headers}).toPromise().catch(ex => {
      console.log(ex);
    });
    this.loadUser();
  }
}
