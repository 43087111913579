export class Rights {
  static ADMIN_DELETE_TICKET = 'ADMIN_DELETE_TICKETS';
  static ADMIN_SHOW_TICKETS = 'ADMIN_SHOW_TICKETS';
  static ADMIN_SHOW_EQUIPMENT = 'ADMIN_SHOW_EQUIPMENT';
  static ADMIN_EDIT_EQUIPMENT = 'ADMIN_EDIT_EQUIPMENT';
  static ADMIN_SHOW_GLOBAL_SETTINGS = 'ADMIN_SHOW_GLOBAL_SETTINGS';
  static ADMIN_CHANGE_GLOBAL_SETTINGS = 'ADMIN_CHANGE_GLOBAL_SETTINGS';
  static ADMIN_USER_ADMIN = 'ADMIN_USER_ADMIN';
  static ADMIN_SHOW_NUMBERS = 'ADMIN_SHOW_NUMBERS';
  static ADMIN_EDIT_NUMBERS = 'ADMIN_EDIT_NUMBERS';
}
