import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {environment} from "../environments/environment";
import {Router} from "@angular/router";
import {LoginEventListener} from "./login-event-listener";
import {UserDto} from "./user-dto";
import {Rights} from "./rights";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private sessionKey = 'SESSION';
  loginError = null;
  isLoggedIn = false;
  checkInterval: any;
  headers: HttpHeaders;
  eventListener: Array<LoginEventListener>;
  currentUser: UserDto;
	ADMIN_DELETE_TICKET = false;
  ADMIN_SHOW_TICKETS = false;
  ADMIN_SHOW_EQUIPMENT = false;
  ADMIN_EDIT_EQUIPMENT = false;
  ADMIN_SHOW_GLOBAL_SETTINGS = false;
  ADMIN_CHANGE_GLOBAL_SETTINGS = false;
  ADMIN_USER_ADMIN = false;
  ADMIN_SHOW_NUMBERS = false;
  ADMIN_EDIT_NUMBERS = false;


  constructor(private httpClient: HttpClient, private router: Router) {
    this.eventListener = new Array<LoginEventListener>();
  }

  addListener(listener: LoginEventListener) {
    console.log("Added Listener" + listener);
    this.eventListener.push(listener);
  }

  async login(uname: string, pass: string, stayLoggedIn: boolean): Promise<boolean> {
    const url = environment.backendUrl + '/authenticate';
    const body = {
      username: uname,
      password: pass
    };
    try {
      const tokenData = await this.httpClient.post(url, body).toPromise();
      if(stayLoggedIn) {
        localStorage.setItem(this.sessionKey, tokenData['token']);
      }
      await this.userLoggedIn(tokenData['token']);
      this.router.navigate(['']);
      return true;
    } catch (ex) {
      localStorage.removeItem(this.sessionKey);
      this.handleError((ex as HttpErrorResponse));
      return false;
    }
  }

  logout() {
    localStorage.removeItem(this.sessionKey);
    const url = environment.backendUrl + '/close_session';
    this.httpClient.post(url, null, {headers: this.headers}).subscribe(() => {
      this.isLoggedIn = false;
      clearInterval(this.checkInterval);
      this.eventListener = new Array<LoginEventListener>();

      this.router.navigate(['session/signin']);
    });
  }

  async checkLogin(): Promise<boolean> {
    if(this.isLoggedIn === true) {
      return this.isLoggedIn;
    }

    const storedToken = localStorage.getItem(this.sessionKey);
    if(storedToken === null) {
      return false;
    }

    const result = await this.userLoggedIn(storedToken);
    console.log(result);
    return result;
  }

  async userLoggedIn(token: string): Promise<boolean> {
    try {
      this.buildHeaders(token);
      const currentUser: UserDto = await this.getRightsBlocking();
      this.checkUserRights(currentUser);
      this.isLoggedIn = true;
      this.loginError = null;
      this.startPeriodicRightCheck();
      this.eventListener.forEach(x => x.successfullyLoggedIn());
      return true;
    } catch (e) {
      this.handleError((e as HttpErrorResponse));
      return false;
    }
  }

  startPeriodicRightCheck() {
    this.checkInterval = setInterval(() => {
      this.httpClient.get<UserDto>(environment.backendUrl + '/loginState', {headers: this.headers}).subscribe(data => {
        this.checkUserRights(data);
      }, error => {
        this.handleError((error as HttpErrorResponse));
      });
    }, 5000);

  }

  getRightsBlocking(): Promise<UserDto> {
    return this.httpClient.get<UserDto>(environment.backendUrl + '/loginState', {headers: this.headers}).toPromise();
  }

  checkUserRights(currentUser: UserDto) {
    this.currentUser = currentUser;
    if(this.checkUserRight(Rights.ADMIN_DELETE_TICKET)) {
      this.ADMIN_DELETE_TICKET = true;
    } else {
      this.ADMIN_DELETE_TICKET = false;
    }

    if(this.checkUserRight(Rights.ADMIN_SHOW_TICKETS)) {
      this.ADMIN_SHOW_TICKETS = true;
    } else {
      this.ADMIN_SHOW_TICKETS = false;
    }

    if(this.checkUserRight(Rights.ADMIN_SHOW_EQUIPMENT)) {
      this.ADMIN_SHOW_EQUIPMENT = true;
    } else {
      this.ADMIN_SHOW_EQUIPMENT = false;
    }

    if(this.checkUserRight(Rights.ADMIN_EDIT_EQUIPMENT)) {
      this.ADMIN_EDIT_EQUIPMENT = true;
    } else {
      this.ADMIN_EDIT_EQUIPMENT = false;
    }

    if(this.checkUserRight(Rights.ADMIN_SHOW_GLOBAL_SETTINGS)) {
      this.ADMIN_SHOW_GLOBAL_SETTINGS = true;
    } else {
      this.ADMIN_SHOW_GLOBAL_SETTINGS = false;
    }

    if(this.checkUserRight( Rights.ADMIN_CHANGE_GLOBAL_SETTINGS)) {
      this.ADMIN_CHANGE_GLOBAL_SETTINGS = true;
    } else {
      this.ADMIN_CHANGE_GLOBAL_SETTINGS = false;
    }

    if(this.checkUserRight(Rights.ADMIN_USER_ADMIN)) {
      this.ADMIN_USER_ADMIN = true;
    } else {
      this.ADMIN_USER_ADMIN = false;
    }

    if(this.checkUserRight(Rights.ADMIN_SHOW_NUMBERS)) {
      this.ADMIN_SHOW_NUMBERS = true;
    } else {
      this.ADMIN_SHOW_NUMBERS = false;
    }

    if(this.checkUserRight(Rights.ADMIN_EDIT_NUMBERS)) {
      this.ADMIN_EDIT_NUMBERS = true;
    } else {
      this.ADMIN_EDIT_NUMBERS = false;
    }
  }

  checkUserRight(rightToCheck: string): boolean {
    if(this.currentUser === undefined || this.currentUser.authorities === undefined) {
      return false;
    }
    return this.currentUser.authorities.includes(rightToCheck);
  }


  buildHeaders(token: string) {
    let tmpheaders = new HttpHeaders();
    tmpheaders = tmpheaders.append("Authorization", "Bearer " + token);
    this.headers = tmpheaders;
  }

  handleError(ex: HttpErrorResponse) {
    if(ex.status === 401) {
      this.isLoggedIn = false;
      clearInterval(this.checkInterval);
      this.loginError = ex.error;
      this.router.navigate(['session/signin']);
    }
  }
}
