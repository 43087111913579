import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../auth.service";

@Injectable()
export class MenuService {

  constructor(public translate: TranslateService, private authService: AuthService) {}

  getAll() {

    const ret = new Array();

    if(this.authService.ADMIN_SHOW_TICKETS) {
      ret.push(this.getTicketsEntry());
    }
    if(this.authService.ADMIN_USER_ADMIN) {
      ret.push(this.getUserEntry());
    }
    if(this.authService.ADMIN_SHOW_EQUIPMENT) {
      ret.push(this.getEquipmentEntry());
    }
    if(this.authService.ADMIN_SHOW_GLOBAL_SETTINGS) {
      ret.push(this.getConfigEntry());
    }

    if(this.authService.ADMIN_SHOW_NUMBERS) {
      ret.push(this.getPhoneEntry());
    }
    return ret;
  }

  getTicketsEntry() {
    return {
      link: '/',
      label: 'Tickets',
      icon: 'explore'
    };
  }

  getUserEntry() {
    return {
      link: '/user',
      label: 'Benutzer',
      icon: 'perm_identity'
    };
  }

  getEquipmentEntry() {
    return  {
      link: '/equipment',
      label: 'Test Equipment',
      icon: 'perm_device_information'
    };
  }

  getConfigEntry() {
    return {
      link: '/config',
      label: 'Konfiguration',
      icon: 'settings'
    };
  }

  getPhoneEntry() {
    return {
      link: '/phone',
      label: 'Nummern',
      icon: 'perm_device_information'
    };
  }
}
