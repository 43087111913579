<div class="app mat-typography" [dir]="options.dir" [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
  <ngx-loading-bar color="#009688"></ngx-loading-bar>
  <app-header (toggleSidenav)="sidemenu.toggle()"></app-header>

  <mat-sidenav-container class="app-inner">
    <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()"
      (openedStart)="sidePanelOpened = true" (closedStart)="sidePanelOpened = false" (mouseover)="menuMouseOver()"
      (mouseout)="menuMouseOut()" [perfectScrollbar]="config" [disabled]="isScreenSmall">
      <app-menu (click)="updatePS()" [direction]="options.dir"></app-menu>
    </mat-sidenav>
    <div [perfectScrollbar]="config" [disabled]="isScreenSmall">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>
</div>
