import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { MenuService } from './menu.service';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../../auth.service";
import {LoginEventListener} from "../../login-event-listener";

@Component({
  selector: 'app-menu',
  template: `<ng-material-multilevel-menu [configuration]='config' [items]='menuItems' class='navigation'></ng-material-multilevel-menu>`,
  providers: [MenuService]
})
export class MenuComponent implements OnChanges, OnInit, OnDestroy, LoginEventListener {
  @Input() direction: string;

  private langChangeSubscription!: Subscription;
  currentLang = 'en';
  menuItems = [];

  config: any;

  constructor(
    public translate: TranslateService,
    public menuService: MenuService,
    private authService: AuthService
  ) {
    authService.addListener(this);
    this.config = {
      paddingAtStart: false,
      interfaceWithRoute: true,
      collapseOnSelect: true,
      highlightOnSelect: true,
      rtlLayout: this.direction === 'rtl' ? true : false,
      selectedListFontColor: '#3f51b5',
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.config = {...this.config, rtlLayout: this.direction === 'rtl' ? true : false};
  }

  ngOnInit() {
    if(this.authService.isLoggedIn) {
      this.successfullyLoggedIn();
    }
  }

  ngOnDestroy() {
    if(this.langChangeSubscription !== undefined) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  successfullyLoggedIn() {
    const menu =  this.menuService.getAll();
    this.menuItems = menu;
  }
}
