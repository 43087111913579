<div mat-dialog-title>
  QR Code für Anlage {{number}}
</div>
<div mat-dialog-content>
  <mat-card>
    <mat-card-title>Equipment auf Gültigkeit prüfen</mat-card-title>
    <mat-card-content>
      <div fxLayout="row wrap" class="text-uppercase">
        <div fxFlex="80%">
          <mat-form-field style="width: 100%" class="mb-1">
            <input [(ngModel)]="numberToCheck" matInput placeholder="Geben Sie die Nummer des Equipments ein" required>
          </mat-form-field>
        </div>
        <div fxFlex="20%">
          <button mat-flat-button color="accent" class="checkEquipment"
                  matTooltip="Equipment prüfen" (click)="checkNumber()">
            <mat-icon>check_circle_outline</mat-icon>
          </button>
        </div>
        <div>
          Antwort:
          <div *ngIf="response">
            Der angegebene Code ist gültig!
          </div>
          <div *ngIf="!response">
            Der angegebene Code ist nicht gültig!
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>QR Code generieren</mat-card-title>
    <mat-card-content>
      <mat-form-field style="width: 100%" class="mb-1">
        <mat-label>Breite in Pixel eingeben</mat-label>
        <input matInput [(ngModel)]="width" name="width">
      </mat-form-field>
      <div>
        <qr-code [value]="code" [size]="width"></qr-code>
      </div>
    </mat-card-content>
  </mat-card>


</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Schließen</button>
</div>
